@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

html,
body {
  font-family: "Inter", serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  background-color: #282A2F;
  height: 100% !important;
}

@media (min-width: 768px) {

  body .section-start {
    padding-top: 160px;
  }

  body .cover-container {
    min-height: calc(100vh - 56px);
  }
}

@media (max-width: 767.98px) {

  body .section-start {
    padding-top: 120px;
  }
  body .cover-container.section-start {
    min-height: calc(100vh - 56px);
  }
}

/* MODIFIERS */
.text-bg-dark {
  background-color: #282A2F !important;
  color: #ffffff;
}

.small {
  font-size: 70%;
}

.rounded {
  border-radius: 10px !important;
}

/* BUTTONS */
.btn .icon {
  margin-right: -7px;
}

/* Overwrite Bootstrap btns */
/* Primary */
.btn-primary {
  background-color: #282a2f !important;
  color: #ffffff !important;
  border: 2px solid #ffffff !important;
  border-radius: 4px !important;
  padding: 10px 20px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  transition: all 0.3s ease !important;
}

.btn-primary .arrow-icon {
  font-size: 16px;
}

.btn-primary:hover {
  background-color: #ffffff !important;
  color: #282a2f !important;
}

/* Secondary */
.btn-secondary {
  background-color: transparent !important;
  color: #ffffff !important;
  border: 2px solid #ffffff !important;
  border-radius: 4px !important;
  padding: 10px 20px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  transition: all 0.3s ease !important;
}

.btn-secondary .arrow-icon {
  font-size: 16px;
}

.btn-secondary:hover {
  background-color: #ffffff !important;
  color: #282a2f !important;
}

/* TITLES */
.main-title {
  font-weight: 600;
}

/* CARDS */
.card-gs-custom {
  background: none;
  border: none;
  border-radius: 8px !important;
  overflow: hidden;
  flex-flow: row nowrap;
  display: flex;
  align-items: flex-end !important;
}

.card-gs-custom .card-img-top {
  border-radius: 4px;
  width: 100%;
  height: auto;
  display: block;
  box-sizing: border-box;
  width: 100%;
  flex: none;
}

.card-gs-custom .card-body {
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  flex: none;
  margin-left: -100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-gs-custom .card-body .card-text {
  font-size: 22px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 0;
}

.card-gs-custom .card-body .btn.btn-primary {
  background: none !important;
  border: none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  color: inherit;
  padding: 0;
  transition: color 0.3s ease;
  font-weight: 400 !important;
}

.card-gs-custom .card-body .btn.btn-primary .icon {
  transition: all 0.3s ease !important;
}

.card-gs-custom .card-body .btn.btn-primary:hover,
.card-gs-custom .card-body .btn.btn-primary:focus {
  background: none !important;
  border: none !important;
  color: #ffffff !important;
}


.card-gs-custom:hover .card-body .btn.btn-primary .icon {
  margin-left: 5px;
}

/* LOGO & HEADER */
header {
  transition: all .5s ease-in-out;
  height: 160px;
  flex-direction: column;
}

header .logo-item {
  text-align: center;
  transition: all .5s ease-in-out;
}

header .logo-item a {
  background-color: transparent !important;
}

header .logo-item a .logo {
  width: 150px;
}

header .nav-item {
  margin: 0 5px;
}

header .nav-item a {
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 600;
  border-radius: 4px;
  border: solid 1px transparent;
}

header .nav-item a.nav-link:focus,
header .nav-item a.nav-link:hover,
header .nav-item a.nav-link.active {
  color: #ffffff;
  border-color: #ffffff;
  background-color: transparent !important;
}

/* Disable scrolling when menu is open */
.no-scroll {
  overflow: hidden;
  height: 100%;
}

@media (min-width: 768px) {

  header .logo-item {
    padding-bottom: 20px;
  }

  .navbar-expand-md .navbar-collapse {
    flex: none;
  }

  header.scrolled {
    height: 95px;
    flex-direction: row;
  }

  header.scrolled .logo-item {
    width: 152px !important;
    margin-right: 25px;
    padding-bottom: 0px;
  }
}

@media (max-width: 767.98px) {

  header .logo-item {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  header {
    flex-direction: row;
    height: auto;
  }

  header .logo-item {
    width: 152px !important;
  }

  header .logo-item {
    margin-right: auto;
  }

  header .logo-item .logo {
    width: 148.45px;
  }

  header .navbar-collapse {
    display: flex;
    position: absolute;
    top: 100%;
    background: #282A2F;
    width: 100%;
    overflow: hidden;
    max-height: 0;
    transition: max-height .35s ease-out;
    /* Smooth transition on height change */
  }

  header .navbar-collapse.collapsing {
    height: calc(100vh - 97px) !important;
  }

  header .navbar-collapse.show {
    max-height: calc(100vh - 97px);
    height: calc(100vh - 97px);
  }

  header .navbar-collapse .nav {
    display: flex;
    flex-direction: column;
    margin: 20% auto auto auto;
    transition: opacity .35s ease-in-out;
  }

  header .navbar-collapse.collapsing .nav {
    opacity: 0;
  }

  header .navbar-collapse.show .nav {
    opacity: 1;
  }

  header .navbar-collapse .nav .nav-item {
    margin: 10px 0;
  }

  header .navbar-collapse .nav .nav-item a {
    font-size: 25;
    font-weight: 700;
  }
}

@media (min-width: 768px) {
  .main-title {
    font-size: 70px;
    line-height: 60px;
  }

  .copy-larger {
    font-size: 20px;
    line-height: 34px;
  }
}

@media (max-width: 767.98px) {
  .main-title {
    font-size: 35px;
    line-height: auto;
  }

  .copy-larger {
    font-size: 16px;
  }
}

@media (min-width: 576px) {
  .sub-title {
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 575.98px) {
  .sub-title {
    font-size: 18px;
  }
}

/* HOME */
/* HERO BANNER */
.hero-banner {
  background: url('../images/bg-home-hero.avif') no-repeat center;
  background-size: cover;
  position: relative;
}

.hero-banner::before {
  content: "";
  position: absolute;
  background: rgb(40, 42, 47);
  background: linear-gradient(180deg, rgba(40, 42, 47, 1) 0, rgba(40, 42, 47, 1) 180px, rgba(40, 42, 47, 0) 100%);
  top: 0px;
  width: 100%;
  height: 300px;
  pointer-events: none;
  z-index: 1;
}

.hero-banner::after {
  content: "";
  position: absolute;
  background: rgb(40, 42, 47);
  background: linear-gradient(360deg, rgba(40, 42, 47, 1) 15%, rgba(40, 42, 47, 0.7847732843137255) 45%, rgba(40, 42, 47, 0) 100%);
  bottom: 0px;
  width: 100%;
  height: 200px;
  pointer-events: none;
  z-index: 1;
}

.hero-banner .text-container {
  position: relative;
  color: #ffffff;
  height: 100%;
  z-index: 2;
}

.hero-banner .text-container .sub-title {
  font-weight: 600;
}

.hero-banner .text-container .main-title {
  font-weight: 600;
  font-size: 90px;
  line-height: 70px;
  margin-left: -4px;
  margin-top: 50px;
  text-shadow: 0px 0px 16px rgba(0, 0, 0, 0.6);
}

.hero-banner .text-container .btn-container {
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767.98px) {
  .hero-banner::before {
    content: "";
    background: rgb(40, 42, 47);
    background: linear-gradient(180deg, rgba(40, 42, 47, 1) 0, rgba(40, 42, 47, 1) 100px, rgba(40, 42, 47, 0) 100%);
    height: 200px;
    z-index: 1;
  }

  .hero-banner .text-container .sub-title {
    text-align: center;
  }

  .hero-banner .text-container .main-title {
    font-size: 90px;
    line-height: 70px;
    width: 520px;
    margin: 40px auto 0;
  }

}

@media (max-width: 575.98px) {
  .hero-banner .text-container .main-title {
    font-size: 14cqw;
    line-height: 13cqw;
    margin-top: 21px;
    width: 100%;
    text-align: center;
  }

  .hero-banner .text-container .main-title span {
    display: block;
    font-size: 13cqw;
  }

}

/* OUR VISION */
.home-our-vision {
  background: url('../images/bg-home-ourvision.avif') no-repeat center #282A2F;
  background-size: cover;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  height: calc(100vh - 95px);
}

.home-our-vision::after {
  content: "";
  position: absolute;
  background: rgb(40, 42, 47);
  background: linear-gradient(360deg, rgba(40, 42, 47, 1) 0, rgba(40, 42, 47, 0) 100%);
  bottom: 0px;
  width: 100%;
  height: 150px;
  pointer-events: none;
  z-index: 1;
  left: 0;
}

.home-our-vision .text-container {
  background: rgba(0, 0, 0, 44%);
  backdrop-filter: blur(26px);
}

.home-our-vision .text-container .text-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 768px) {
  .home-our-vision {
    min-height: 630px;
  }

  .home-our-vision .text-container {
    width: calc(100% - 6rem);
    padding: 70px 50px;
    z-index: 2;

  }

  .home-our-vision .text-container .text-wrap {
    max-width: 750px;
    margin: auto;
    min-height: 390px;
    height: calc(100vh - 334px);
    max-height: 540px;
  }

  .home-our-vision .text-container .sub-title {
    font-weight: 600;
    margin-bottom: 25px;
  }

  .home-our-vision .text-container .main-title {
    font-weight: 600;
    margin-bottom: 25px;
  }

  .home-our-vision .text-container .copy {
    font-size: 24px;
    line-height: 40px;
  }
}

@media (max-width: 767.98px) {
  .home-our-vision {
    min-height: 750px;
  }

  .home-our-vision .text-container {
    padding: 30px 20px;
  }

  .home-our-vision .text-container .text-wrap {
    margin: auto;
    min-height: 390px;
  }

  .home-our-vision .text-container .text-wrap .sub-title {
    font-weight: 600;
    margin-bottom: 16px;
  }

  .home-our-vision .text-container .text-wrap .main-title {
    font-weight: 600;
    margin-bottom: 16px;
  }

  .home-our-vision .text-container .text-wrap .copy {
    font-size: 24px;
    line-height: 35px;
  }
}

@media (max-width: 575.98px) {
  .home-our-vision .text-container .text-wrap .copy {
    font-size: 18px;
  }
}

/* OUR PARTNERS */
.home-our-partners {
  background: url('../images/bg-home-ourpartners.avif') no-repeat center #282A2F;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.home-our-partners::before {
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 100vw 100px 0;
  border-color: transparent #282A2F transparent transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.home-our-partners::after {
  content: " ";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 100px 100vw;
  border-color: transparent transparent #282A2F transparent;
  position: absolute;
  bottom: 0;
  left: 0;
}

.home-our-partners .partners-box {
  background-color: #282A2F;
  border: 1px solid #4c4c4c;
}

.home-our-partners .text-container .main-title {
  margin-bottom: 15px;
}

.home-our-partners.noafter::after {
  display: none;
}

@media (min-width: 1200px) {
  .home-our-partners {
    min-height: 600px;
    height: calc(100vh - 95px);
    padding-top: 250px;
    padding-bottom: 250px;
  }
}

@media (max-width: 1199.98px) {
  .home-our-partners {
    padding-top: 200px;
    padding-bottom: 200px;
  }

}

@media (max-width: 575.98px) {
  .home-our-partners {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .home-our-partners::before {
    content: " ";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 100vw 60px 0;
    border-color: transparent #282A2F transparent transparent;
  }

  .home-our-partners::after {
    content: " ";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 60px 100vw;
    border-color: transparent transparent #282A2F transparent;
  }

  .home-our-partners .partners-box {
    height: 205px;
  }

  .home-our-partners .partners-box img {
    max-width: 280px;
  }
}

/* CONTACT US */
.home-contact-us {
  background: url('../images/bg-home-contactus.avif') no-repeat center #282A2F;
  background-size: cover;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  height: calc(100vh - 95px);
}

.home-contact-us .text-container {
  background: rgba(0, 0, 0, 44%);
  backdrop-filter: blur(26px);
}

.home-contact-us .text-container .text-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 768px) {
  .home-contact-us {
    min-height: 630px;
  }

  .home-contact-us .text-container {
    width: calc(100% - 6rem);
    padding: 70px 50px;
    z-index: 2;

  }

  .home-contact-us .text-container .text-wrap {
    margin: auto;
    min-height: 390px;
    height: calc(100vh - 334px);
    max-height: 540px;
  }

  .home-contact-us .text-container .text-wrap .main-title {
    font-size: 50px;
  }

  .home-contact-us .text-container .text-wrap .box {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #ffffff;
    min-height: 400px;
  }

  .home-contact-us .text-container .text-wrap .box.box-contact-01 {
    background: url('../images/bg-contactus-box01.avif') no-repeat center;
    background-size: cover;
  }

  .home-contact-us .text-container .text-wrap .box.box-contact-02 {
    background-image: url('../images/bg-solutions-box-02.avif');
    background-repeat: no-repeat;
    background-size: cover;
    background: linear-gradient(180deg, rgba(40, 42, 47, 1) 0%, rgba(40, 42, 47, 0) 65%);
  }
}

@media (max-width: 767.98px) {
  .home-contact-us {
    height: auto;
  }

  .home-contact-us .text-container {
    padding: 30px 20px;
  }

  .home-contact-us .text-container .text-wrap {
    margin: auto;
    min-height: 390px;
  }
}

/* ABOUT */
.about-hero-banner {
  background: url('../images/bg-about-hero.avif') 57% center #282A2F no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 50px;
}

.about-hero-banner::before {
  content: "";
  position: absolute;
  background: rgb(40, 42, 47);
  background: linear-gradient(90deg, rgba(40, 42, 47, 1) 0%, rgba(40, 42, 47, 0) 65%);
  top: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}

.about-hero-banner .text-container {
  position: relative;
  z-index: 2;
}

.about-hero-banner .text-container .text-wrapper {
  max-width: 700px;
}

@media (max-width: 767.98px) {
  .about-hero-banner {
    align-items: flex-start !important;
  }

  .about-hero-banner .btn-container {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);

  }

  .about-hero-banner .text-container {
    height: 100%;
    text-align: center;
  }

  .about-hero-banner .text-container .text-wrapper {
    max-width: 700px;
  }
}

/* ABOUT THE COMPANY */
.about-the-company {
  background: rgb(65, 67, 72);
  background: linear-gradient(45deg, rgba(65, 67, 72, 1) 20%, rgba(20, 24, 34, 1) 100%);
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (max-width: 767.98px) {
  .about-the-company {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

/* ABOUT OUR VISION */
.about-our-vision {
  background: #212327;
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (max-width: 767.98px) {
  .about-our-vision {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.about-our-vision .text-wrap {
  max-width: 800px;
  margin: auto;
}

/* ABOUT OUR STANDARD */
.about-our-standard {
  background: rgb(20, 24, 34);
  background: linear-gradient(45deg, rgba(20, 24, 34, 1) 30%, rgba(65, 67, 72, 1) 100%);
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (max-width: 767.98px) {
  .about-our-standard {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.about-our-standard .text-wrap {
  max-width: 800px;
  margin: auto;
}

/* ABOUT OUR VALUE */
.about-our-value {
  background: #212327;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
}

/* ABOUT OUR PRODUCTS */
.about-our-products {
  background: #282A2F;
  position: relative;
  padding-top: 150px;
  padding-bottom: 150px;
}

.about-our-products .img-wrapper {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-our-products .img-wrapper img {
  width: 100%;
  max-width: 120px;
}

.about-our-products .text-wrap .sub-title {
  font-size: 30px;
  line-height: 44px;
}

/* SOLUTIONS HERO */
.solutions-hero-banner {
  background: url('../images/bg-solutions-hero.avif') 57% center #282A2F no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 50px;
}

.solutions-hero-banner::before {
  content: "";
  position: absolute;
  background: rgb(40, 42, 47);
  background: linear-gradient(90deg, rgba(40, 42, 47, 1) 0%, rgba(40, 42, 47, 0) 65%);
  top: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}

.solutions-hero-banner .text-container {
  position: relative;
  z-index: 2;
}

.solutions-hero-banner .text-container .text-wrapper {
  max-width: 700px;
}

@media (max-width: 767.98px) {
  .solutions-hero-banner {
    align-items: flex-start !important;
  }

  .solutions-hero-banner .btn-container {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);

  }

  .solutions-hero-banner .text-container {
    height: 100%;
    text-align: center;
  }

  .solutions-hero-banner .text-container .text-wrapper {
    max-width: 700px;
  }
}

/* SOLUTIONS */
.solutions {
  background: #282A2F;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
}

.solutions .solutions-box {
  background-color: #212327;
  border-radius: 4px;
  border: 1px solid #ffffff;
  padding: 35px;
}


.solutions .solutions-box.naval {
  background: url('../images/bg-solutions-box-01.avif') no-repeat center;
  background-size: cover;
}

.solutions .solutions-box.portableweapons {
  background: url('../images/bg-solutions-box-02.avif') no-repeat center;
  background-size: cover;
}

.solutions .solutions-box.lifeextension {
  background: url('../images/bg-solutions-box-03.avif') no-repeat center;
  background-size: cover;
}

.solutions .solutions-box.obsolescencemanagement {
  background: none;
}

.solutions .solutions-box.integratedweapons {
  background: url('../images/bg-solutions-box-04.avif') no-repeat center;
  background-size: cover;
}

.solutions .solutions-box.nonlethal {
  background: url('../images/bg-solutions-box-05.avif') no-repeat center;
  background-size: cover;
}

.solutions .solutions-box.ummannedautomated {
  background: url('../images/bg-solutions-box-06.avif') no-repeat center;
  background-size: cover;
}

.solutions .solutions-box.lightweighthovercraft {
  background: url('../images/bg-solutions-box-07.avif') no-repeat center;
  background-size: cover;
}

.solutions .solutions-box.firecontrol {
  background: url('../images/bg-solutions-box-08.avif') no-repeat center;
  background-size: cover;
}

.solutions .solutions-box.eod {
  background: url('../images/bg-solutions-box-09.avif') no-repeat center;
  background-size: cover;
}

.solutions .solutions-box.comunication {
  background: url('../images/bg-solutions-box-10.avif') no-repeat;
  background-size: cover;
  background-position-x: 24%;
}

@media (min-width: 992px) {
  .solutions .solutions-box {
    height: 600px;
  }

  .solutions .col-12.col-lg-6:has(.small-box),
  .solutions .solutions-box.small-box {
    height: 285px;
  }

  .col-12.col-lg-6.mb-4:nth-child(4) {
    margin-top: -310px;
    margin-left: 50%;
  }
}

@media (max-width: 991.98px) {
  .solutions .solutions-box {
    min-height: 380px;
  }
}

@media (min-width: 768px) {
.home-who-are-we {
  padding-top: 100px;
  padding-bottom: 100px;
}
}

/* CONTACT */
@media (min-width: 768px) {
  .contact-page .container {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media (max-width: 767.98px) {
  .contact-page .container {
    padding-bottom: 50px;
  }
}

/* FOOTER */
footer {
  background-color: #282A2F;
  color: #ffffff;
  padding: 50px 0 .25rem;
}

footer .footer-logo {
  width: 250px;
}

@media (max-width: 767.98px) {
  footer .nav-link {
    font-size: 20px;
    text-align: center;
  }

  footer h5 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
}